import React from "react"
import "./styles.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "../../utils"
import { FormattedMessage } from "gatsby-plugin-intl"

const AwardsSection = () => {
  const { t } = useTranslation()

  return (
    <section className="awards-section">
      <div className="container">
        <div className="awards-section__title">{t("Awards")}</div>
        <div className="row">
          <div className="col-4 col-md-12 wt-margin-bottom-25">
            <FormattedMessage
              id="The best SAAS & B2B solution at the Echelon Asia Summit 2018 in Singapore"
              defaultMessage="The best SAAS & B2B solution at the Echelon Asia Summit 2018 in Singapore"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </div>

          <div className="col-4 col-md-12 wt-margin-bottom-25">
            <FormattedMessage
              id="Offensive Security Certified Professionals (OSCP), OSCE, CEH, CISA, CHFI"
              defaultMessage="Offensive Security Certified Professionals (OSCP), OSCE, CEH, CISA, CHFI"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </div>

          <div className="col-4 col-md-12">
            <FormattedMessage
              id="1st & 2nd places at the biggest international hacking competitions."
              defaultMessage="1st & 2nd places at the biggest international hacking competitions."
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AwardsSection
