import React from "react"
import Olzhas from "../../images/olzhas.jpeg"
import MissionImage from "../../images/mission.png"
import LinkedIn from "../../images/linkedin.png"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./styles.css"
import { useTranslation } from "../../utils"

const TeamSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tsarka-team.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="team-section">
      <div className="container">
        <h1 className="team-section__title">{t("Meet the Team")}</h1>

        <div className="meet-the-team__content">
          <div className="text">
            <div className="team-section__subtitle">{t("Culture")}</div>
            <p>
              {t(
                "We are a group of ethical cybersecurity professionals, who have been winning international hacking competitions for the last three years. We work from different international locations across Europe and Asia. We speak eight languages and are enthusiastic to go global!"
              )}
            </p>
          </div>
          <div className="profile">
            <img src={Olzhas} alt="Team" className="profile__image" />
            <div>
              <strong>Olzhas Satiyev</strong>
            </div>
            <div>CEO / OSCP / CEH</div>
            <a
              href="https://www.linkedin.com/in/nfmka/"
              target="_blank"
              rel="noopener"
            >
              <img src={LinkedIn} alt="" className="profile__icon" />
            </a>
          </div>
        </div>

        <div className="team-section__content">
          <img
            src={MissionImage}
            alt="mission"
            className="display-md-none.display-sm-none.display-xs-none"
          />
          <div className="team-section__text" align="right">
            <div className="team-section__subtitle">{t("Mission")}</div>
            <p>
              {t(
                "We believe security is a foundational right for everybody. Our mission is to prevent the global epidemic of website infection by providing free essential protection from the existing cyber threats. Sign up for free and let’s make the Internet safer!"
              )}
            </p>
          </div>
        </div>

        <div className="team-section__photo">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default TeamSection
