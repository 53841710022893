import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import TeamSection from "../../sections/team"
import AwardsSection from "../../sections/awards"
import Footer from "../../sections/footer"
import { useTranslation } from "../../utils"

const TeamPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("Team")} />
      <Header />

      <div className="team-page">
        <TeamSection />
        <AwardsSection />

        <Footer />
      </div>
    </Layout>
  )
}

export default TeamPage
